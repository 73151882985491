
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";
import { isInteger } from "@/tools";

import MaterialShadeAutocomplete from "@/components/General/Autocompletes/Equipments/MaterialShadeAutocomplete.vue";

import { V2CalibrationBlockItem } from "@/domain";

import { IV2CalibrationBlockService } from "@/services";

@Component({
  components: {
    MaterialShadeAutocomplete,
  },
  data: () => {
    return {
      isInteger,
    };
  },
})
export default class V2CalibrationBlockCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  forbiddenSerialNumbers!: string[];

  get v2CalibrationBlockService() {
    return this.container.resolve<IV2CalibrationBlockService>(
      S.V2_CALIBRATION_BLOCK_SERVICE
    );
  }

  serialNumber: string | null = null;
  dimensionValue: number | null = null;
  materialShadeId: string | null = null;
  celerity: number | null = null;
  pvNumber: string | null = null;

  async create() {
    try {
      this.$emit("update:loading", true);
      const block = await this.v2CalibrationBlockService.create({
        serialNumber: this.serialNumber!,
        dimensionValue: this.dimensionValue!,
        materialShadeId: this.materialShadeId!,
        celerity: this.celerity!,
        pvNumber: this.pvNumber!,
      });
      this.$emit("create", V2CalibrationBlockItem.fromDetails(block));
    } finally {
      this.$emit("update:loading", false);
    }
  }

  isAllowed(serialNumber: string) {
    return (
      _.findIndex(
        this.forbiddenSerialNumbers,
        (v) => v.toUpperCase() == serialNumber.toUpperCase()
      ) != -1
    );
  }
}
