
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import _ from "lodash";

import { CalibrationBlockCategory } from "@/domain";

import CreateUi from "@/components/Core/Create/CreateUI.vue";
import V1CalibrationBlockCreate from "@/components/Core/Create/Equipments/CalibrationBlocks/Children/V1CalibrationBlockCreate.vue";
import V2CalibrationBlockCreate from "@/components/Core/Create/Equipments/CalibrationBlocks/Children/V2CalibrationBlockCreate.vue";
import StepCalibrationBlockCreate from "@/components/Core/Create/Equipments/CalibrationBlocks/Children/StepCalibrationBlockCreate.vue";

@Component({
  components: {
    CreateUi,
    V1CalibrationBlockCreate,
    V2CalibrationBlockCreate,
    StepCalibrationBlockCreate,
  },
  data: () => {
    return {
      CalibrationBlockCategory,
    };
  },
})
export default class CalibrationBlockCreate extends Vue {
  @Prop({ required: true })
  forbiddenSerialNumbers!: {
    value: string;
    category: CalibrationBlockCategory;
  }[];

  @Ref("child")
  child!: any;

  get items() {
    return [
      {
        value: CalibrationBlockCategory.v1,
        text: "Cale V1",
      },
      {
        value: CalibrationBlockCategory.v2,
        text: "Cale V2",
      },
      {
        value: CalibrationBlockCategory.step,
        text: "Cale à Gradins",
      },
    ];
  }

  get v1Component() {
    return () =>
      import(
        "@/components/Core/Create/Equipments/CalibrationBlocks/Children/V1CalibrationBlockCreate.vue"
      );
  }

  get v2Component() {
    return () =>
      import(
        "@/components/Core/Create/Equipments/CalibrationBlocks/Children/V2CalibrationBlockCreate.vue"
      );
  }

  get stepComponent() {
    return () =>
      import(
        "@/components/Core/Create/Equipments/CalibrationBlocks/Children/StepCalibrationBlockCreate.vue"
      );
  }

  get forbiddenSerialNumbersFiltered() {
    return _.map(
      _.filter(
        this.forbiddenSerialNumbers,
        (f) => f.category == this.items[this.tab].value
      ),
      (b) => b.value
    );
  }

  loading: boolean = false;
  tab: number = 0;

  getComponent(category: { text: string; value: CalibrationBlockCategory }) {
    if (category.value == CalibrationBlockCategory.v1) {
      return this.v1Component;
    } else if (category.value == CalibrationBlockCategory.v2) {
      return this.v2Component;
    } else {
      return this.stepComponent;
    }
  }

  customCreate() {
    this.child[0].create();
  }
}
